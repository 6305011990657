export const showNewsletterFrom = () => {
  //checkout dynamically show newsletter data
  const newsletterCheckbox = document.getElementById("newsletter-checkbox");
  if (newsletterCheckbox) {
    newsletterCheckbox.addEventListener("click", () => {
      if (newsletterCheckbox.checked) {
        document.querySelector(".newsletter-data").style.display = "block";
      } else {
        document.querySelector(".newsletter-data").style.display = "none";
      }
    });
  }
}