import fontawesome from '@fortawesome/fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {navInit} from './scripts/navigation';
import { showCompanyDataFrom } from './scripts/showCompanyDataForm';
import { showNewsletterFrom } from './scripts/showNewsletterFrom';
import { openFilter } from './scripts/openFilter';
import { slickInit } from './scripts/slickInit';
import { productCarouselInit } from './scripts/productCarousel';
import { blogInit } from './scripts/blog';
// import { blogHomeInit } from './scripts/blogHome';
import {customPopupInit} from "./scripts/customPopup";
fontawesome.library.add(far, fab, fas);

navInit();
openFilter();
slickInit();
productCarouselInit();

if(document.querySelector('.customPopup__BG')) {
  customPopupInit();
}

if(document.getElementById('blog_wrapper')) {
  blogInit();
}
// if(document.getElementById('blogHome_wrapper')) {
//   blogHomeInit();
// }

(document.querySelector('.checkout-data')) ? showCompanyDataFrom() : "";
(document.querySelector('.checkout-data')) ? showNewsletterFrom() : "";


jQuery(document).ready(function ( $ ) {
  if (window.innerWidth > 768) {
    jQuery('.guaven_woos_suggestion').appendTo('#searchform');
    jQuery('.guaven_woos_suggestion').css('left', 'auto');
    jQuery('.guaven_woos_suggestion').css('right', 0);
    jQuery('.guaven_woos_suggestion').css('top', 0);
  }

  // smooth scroll to ID
  jQuery('.menu-item a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    jQuery('html, body').animate({
      scrollTop: jQuery(jQuery(this).attr('href')).offset().top - 120 // choose what you need
    }, 500, 'linear');
  });

    /** Change Color Icon on hover */
    jQuery(".tinvwl_add_to_wishlist_button img")
    .mouseover(function() {
      jQuery(this).attr("src", "/wp-content/themes/flexible-theme/assets/img/dodaj_do_ulubionych_hover.svg");
    })
    .mouseout(function() {
      jQuery(this).attr("src", "/wp-content/themes/flexible-theme/assets/img/dodaj_do_ulubionych.svg");
    });
    jQuery(window).on('load', function () {
      jQuery(".tinvwl_add_to_wishlist_button").attr("rel", "nofollow");
    });
});

// change cart quantity
function incrementCart() {
  const updateButton = document.querySelector(".actions > .coupon> button");
  if(!updateButton) return;
  this.parentNode.querySelector(`input.text`).stepUp();
  updateButton.disabled = false;
  updateButton.click();
}
function decrementCart() {
  const updateButton = document.querySelector(".actions>.coupon>button");
  if(!updateButton) return;
  this.parentNode.querySelector(`input.text`).stepDown();
  updateButton.disabled = false;
  updateButton.click();
}
function listenOnCart() {
  const arrowsUp = document.querySelectorAll(".qty_button.plus");
  const arrowsDown = document.querySelectorAll(".qty_button.minus");
  for (const arrow of arrowsUp) {
    arrow.onclick = incrementCart;
  }
  for (const arrow of arrowsDown) {
    arrow.onclick = decrementCart;
  }
}
listenOnCart();
const config = { attributes: true, childList: true, subtree: true };
const observer = new MutationObserver(listenOnCart);
observer.observe(document, config);