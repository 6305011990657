import 'slick-carousel';
import $ from 'jquery';

export function slickInit() {
  jQuery(document).ready(function () {
    $(".section_banner_homesite__wrapper").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
    });
  });
  jQuery(document).ready(function () {
    $(".section_promo_banner__wrapper").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
    });
  });

  jQuery('.section_banner_homesite__wrapper').on('init', function(event, slick){
    var stHeight = jQuery('.section_banner_homesite__wrapper .slick-track').height();
    jQuery('.section_banner_homesite__wrapper .slick-slide').css('height',stHeight + 'px' );
  });

  jQuery(document).ready(function () {
    $(".section_textWithBadges_homesite__slider").slick({
      autoplay: true,
      autoplaySpeed: 1500,
      slidesToShow: 8,
      slidesToScroll: 1,
      infinite: true,
      speed: 500,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1, 
          }
        }
      ]
    });
  });

  jQuery(document).ready(function () {
    $(".section_carouselWithText_subsite__galleryWrapper").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplaySpeed: 4000,
      infinite: true,
    });
  });
}

export function startCarousel(localization) {

  jQuery(document).ready(function ($) {
    localization.slick({
      autoplay: true,
      autoplaySpeed: 1500,
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
      speed: 500,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
            centerMode: true,
          }
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            arrows: false,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          }
        }
      ]
    });
  });

  jQuery('.section_textWithBadges_homesite__slider').on('afterChange', function(event, slick){
    /** Change Color Icon on hover */
    jQuery(".tinvwl_add_to_wishlist_button").attr("rel", "nofollow");
    jQuery(".tinvwl_add_to_wishlist_button img")
    .mouseover(function() {
      jQuery(this).attr("src", "/wp-content/themes/flexible-theme/assets/img/dodaj_do_ulubionych_hover.svg");
    })
    .mouseout(function() {
      jQuery(this).attr("src", "/wp-content/themes/flexible-theme/assets/img/dodaj_do_ulubionych.svg");
    });
  });
}
