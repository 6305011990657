const axios = require("axios");
export function navInit() {
  jQuery(document).ready(function ($) {
    //obsługa hamburgera i elementów menu
    "use strict";

    $('.c-hamburger').on('click', function () {
        ($('.headerMobile__mainMenu').hasClass("is-active")) ? $('.headerMobile__mainMenu').removeClass("is-active"): $('.headerMobile__mainMenu').addClass("is-active");
        ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");

        
    });
  });

  const mainItems = document.querySelectorAll('.headerMobile__mainMenu__listItem');

  const subMenuElements = document.querySelectorAll('.headerMobile__subMenu__element');

  const subMenuElements2 = document.querySelectorAll('.headerMobile__subMenu__listItem');

  const closeMainItems = () => {
    document.querySelectorAll('.headerMobile__subMenu').forEach(submenu => {
      submenu.classList.remove('open');
    })
  }

  const closeSubmenuElements = () => {
    document.querySelectorAll('.headerMobile__subMenu__list').forEach(submenu => {
      submenu.classList.remove('open');
    })
  }

  const closeSubmenuElements2 = () => {
    document.querySelectorAll('.headerMobile__subMenu__list2').forEach(submenu => {
      submenu.classList.remove('open');
    })
  }

  mainItems.forEach(item => {

    if(item.querySelector('span.arrow')) {
      item.querySelector('span.arrow').addEventListener('click', (e)=> {
        e.preventDefault();
        if(item.querySelector('.headerMobile__subMenu').classList.contains('open')) {
          closeMainItems();
          closeSubmenuElements();
          closeSubmenuElements2();
        } else {
          closeMainItems();
          closeSubmenuElements();
          closeSubmenuElements2();
          item.querySelector('.headerMobile__subMenu').classList.add('open')
        }
      })
    }
  })

  subMenuElements.forEach(item => {

    if(item.querySelector('span.arrow')) {
      item.querySelector('span.arrow').addEventListener('click', (e)=> {
        e.preventDefault();
        if(item.querySelector('.headerMobile__subMenu__list').classList.contains('open')) {
          closeSubmenuElements();
          closeSubmenuElements2();
        } else {
          closeSubmenuElements();
          closeSubmenuElements2();
          item.querySelector('.headerMobile__subMenu__list').classList.add('open')
        }
      })
    }
  })

  subMenuElements2.forEach(item => {

    if(item.querySelector('span.arrow')) {
      item.querySelector('span.arrow').addEventListener('click', (e)=> {
        e.preventDefault();
        if(item.querySelector('.headerMobile__subMenu__list2').classList.contains('open')) {
          closeSubmenuElements2();
        } else {
          closeSubmenuElements2();
          item.querySelector('.headerMobile__subMenu__list2').classList.add('open')
        }
      })
    }
  })



   //ajax add to cart
   window.addEventListener("DOMContentLoaded", () => {
    const addToCartEvent = () => {
      const addToCartButtons = document.querySelectorAll(".add-to-cart");
      for (const addToCart of addToCartButtons) {
        addToCart.onclick = () => {
          addToCart.classList.add("loading");
          addToCart.textContent = "";
          axios
            .get(addToCart.dataset.url)
            .then(() => {
              addToCart.classList.remove("loading");
              addToCart.classList.add("loaded");
              location.reload();
            })
            .catch(e => {
              if (e.response.status !== 400) {
                addToCart.classList.remove("loading");
                addToCart.classList.add("failed");
                location.reload();
              }
            })
            .finally(() => {
              location.reload();
            });
        };
      }
    };
    window.addEventListener(
      "load",
      function() {
        addToCartEvent();
        document.addEventListener("DOMNodeInserted", addToCartEvent, false);
      },
      false
    );
    document.onpropertychange = addToCartEvent;
  });

  //perform ajax request to dynamically change quantity in mini cart
  const minicartMore = document.querySelectorAll(".product-quantity .more");
  const minicartLess = document.querySelectorAll(".product-quantity .less");
  // const cartCount = document.querySelector(".cart-count");
  const freeShippingNode = document.querySelector(".mini-cart-free-shipping");
  const freeShippingNodeTitle = document.querySelector(".mini-cart-free-title");
  const barNode = document.querySelector(".bar-container .bar");
  const totalNode = document.querySelector(".mini-cart-total");
  const discountNode = document.querySelector(".mini-cart-total-discount");
  for (const more of minicartMore) {
    more.addEventListener("click", () => {
      const quantity = more.parentNode.dataset.quantity;
      const id = more.parentNode.dataset.id;
      axios
        .get(
          `${ajax.url}?action=increase_product_quantity&id=${id}&quantity=${quantity}`
        )
        .then(response => {
          const data = response.data;
          if (data.quantity) {
            more.parentNode.parentNode.firstElementChild.innerText =
              data.quantity;
            more.parentNode.dataset.quantity = data.quantity;
            // cartCount.innerText = Number(cartCount.innerText) + 1;
            totalNode.innerText = data.total + " PLN";
            if (data.discount && discountNode) {
              discountNode.innerText = data.discount + " PLN";
            }
            if (data.free_shipping < 0) {
              freeShippingNode.innerText = "";
              freeShippingNodeTitle.innerText = "Gratulacje - otrzymałeś darmową dostawę";
              barNode.style.transform = "translateX(0%)";
            } else {
              freeShippingNodeTitle.innerText = "Do darmowej dostawy brakuje:";
              freeShippingNode.innerText = data.free_shipping + " PLN";
              barNode.style.transform =
                "translateX(" +
                (-data.free_shipping /
                  (Number(data.total) + Number(data.free_shipping))) *
                  100 +
                "%)";
            }
          }
        });
    });
  }
  for (const less of minicartLess) {
    less.addEventListener("click", () => {
      const quantity = less.parentNode.dataset.quantity;
      const id = less.parentNode.dataset.id;
      axios
        .get(
          `${ajax.url}?action=decrease_product_quantity&id=${id}&quantity=${quantity}`
        )
        .then(response => {
          const data = response.data;
          if (data.quantity) {
            if (quantity !== data.quantity) {
              less.parentNode.parentNode.firstElementChild.innerText =
                data.quantity;
              less.parentNode.dataset.quantity = data.quantity;
              // cartCount.innerText = Number(cartCount.innerText) - 1;
            }
            totalNode.innerText = data.total + " PLN";
            if (data.discount && discountNode) {
              discountNode.innerText = data.discount + " PLN";
            }
            if (data.free_shipping < 0) {
              freeShippingNode.innerText = "";
              freeShippingNodeTitle.innerText = "Gratulacje - otrzymałeś darmową dostawę";
              barNode.style.transform = "translateX(0%)";
            } else {
              freeShippingNodeTitle.innerText = "Do darmowej dostawy brakuje:";
              freeShippingNode.innerText = data.free_shipping + " PLN";
              barNode.style.transform =
                "translateX(" +
                (-data.free_shipping /
                  (Number(data.total) + Number(data.free_shipping))) *
                  100 +
                "%)";
            }
          }
        });
    });
  }

}