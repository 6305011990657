import 'slick-carousel';
import {startCarousel} from './slickInit';
import $ from 'jquery';

export function productCarouselInit() {
  const productCarouselsSections = document.querySelectorAll('.section_productCarousel_homesite');
  if(!productCarouselsSections) return;
  productCarouselsSections.forEach(section => {
    let localization = $(section).find('.section_productCarousel_homesite__slider');
    startCarousel(localization);
  })
}   

function loadProducts(localization, parent_cat = "all", type = "promo") {
  localization = jQuery(localization).find('.section_productCarousel_homesite__slider');
  jQuery(document).ready(function ($) {
    $.ajax({
      type: "POST",
      dataType: "json",
      url: ajax.url,
      data: {
        action: "generatedProductsCarousel",
        parent_cat: parent_cat,
        type: type
      },
      beforeSend: function() {
        localization.innerHTML = "laoding";
      },
      success: function(response) {
        if (localization.hasClass("slick-initialized")) {
          localization.slick("destroy");
          localization.empty();
        }
        if(response.length != 0) {
          localization.empty();
          Object.keys(response).forEach(key => {
            localization.append(
              '<div class="slider__box">' + response[key] + "</div>"
            ); // the value of the current key.
          });
          startCarousel(localization);
        } else {
          localization.empty();
          localization.append(
            '<h3>Brak produktów w danej kategorii</h3>'
          ); // the value of the current key.
        }
      },
      cache: false
    });
  });
}