export const showCompanyDataFrom = () => {
  //checkout dynamically show company data
  const companyCheckbox = document.getElementById("company-expense-checkbox");
  if (companyCheckbox) {
    companyCheckbox.addEventListener("click", () => {
      if (companyCheckbox.checked) {
        document.querySelector(".company-data").style.display = "block";
      } else {
        document.querySelector(".company-data").style.display = "none";
      }
    });
  }
}