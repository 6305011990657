let app,
    blog, 
    loadmore,
    categoriesButtons;

const setVariables = async () => {
  categoriesButtons = document.querySelectorAll('.blog__categories__button');
  blog = document.getElementById('blog_wrapper');
  loadmore = document.getElementById('blog_loadmore');
}

class State {
  constructor() {
    this._page = 1;
    this._category = '';
    this._perPage = 6;
  }

  setPage(page) {
    this._page = page;
  }
  getPage() {
    return this._page;
  }
  setCategory(category) {
    this._category = category;
  }
  getCategory() {
    return this._category;
  }
  setPerPage(perPage) {
    this._perPage = perPage;
  }
  getPerPage() {
    return this._perPage;
  }
}

const resetState = () => {
  app.setPage(1);
  app.setCategory('');
  clearBlog();
}

const clearBlog = async () => {
  blog.innerHTML = '';
}

const getPosts = ( category = app.getCategory(), page = app.getPage() ) => {
  jQuery(document).ready(function ($) {
    $.ajax({
      type: "POST",
      dataType: "json",
      url: ajax.url,
      data: {
        action: "generatedPostList",
        category: category,
        offset: page
      },
      beforeSend: function() {
        loadmore.innerHTML = "";
      },
      success: function(response) {
        Object.keys(response['posts']).forEach(key => {
          blog.innerHTML += response['posts'][key]; // the value of the current key.
        });
        if(page < response['max_num_pages']) {
          loadmore.innerHTML = "<a class='loadmore_button buttonUno buttonUno--black'>Załaduj więcej</a>";
          page=page+1;
          app.setPage(page);
          document.querySelector('.loadmore_button').addEventListener('click', ()=>{
            getPosts();
          })
        }
        if(response['posts'].length < 1) {
          blog.innerHTML = "<h3>Nieznaleziono wpisów</h3>"
        }
      },
      cache: false
    });
  });
}

const eventsRun = () => {
  categoriesButtons.forEach(button => {
    button.addEventListener('click', () => {
      const catId = button.getAttribute('data-termID');
      resetState();
      (catId != 'all') ? app.setCategory(catId): app.setCategory();
      document.querySelector('.activeCat').classList.remove('activeCat');
      button.classList.add('activeCat');
      getPosts();
    })
  })
}

export const blogInit = async () => { 
  app = new State();
  await setVariables();
  await getPosts();
  await eventsRun();
}